@use '../variables';

.announcement-content {
  .image-input-container {
    margin-top: 0.25rem;
    position: relative;
    aspect-ratio: 30/4;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: variables.$background-light-grey;
    border-radius: variables.$rounded-md;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: variables.$rounded-md;
    border: 2px dashed variables.$border-grey;
    width: 100%;
    height: 100%;
    background-color: transparent;
    text-align: center;
    line-height: 1.5;
  }

  .thumbnail-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    border-radius: 10px;
    border: 2px dashed #E8EAEF;
    width: 100%;
    min-height: 100%;
    // padding: 16px;
    // background-color: transparent;

    .thumbnail {
      width: 100px;
      max-height: 173px;
      border-radius: 10px;
      object-fit: cover;
    }

    .img-info {
      display: flex;
      flex-direction: column;

      .btn-delete {
        display: flex;
        align-items: center;
        gap: 4px;
        color: palevioletred;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .hint {
    /* Poppins/Reguler 14px */
    font-family: Poppins, Khmer, system ui;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;

    color: rgba(83, 83, 95, 0.5);

  }

  .browse-file-trigger {
    color: variables.$color-primary;
    cursor: pointer;
  }

  .btn-float-remove {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 99999;
  }

  .input-group {
    margin-bottom: 2em;
  }

  .creation-form {
    // max-width: map-get($map: variables.$grid-breakpoints, $key: 'lg');
  }

  // detail
  .description {
    margin-top: 1em;
  }

  .custom-error {
    color: red;
    font-size: 0.8em;

    &.of-quill {
      margin-top: -1em;
      padding-left: 1em;
    }
  }

  .announcement-thumbnail-container {
    overflow: hidden;
    width: 100%;
    height: 196px;
    border-radius: 15px;
    position: relative;
    border: 1px solid #cccccc;

    .announcement-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (min-width: map-get($map: variables.$grid-breakpoints, $key: 'sm')) and (max-width: map-get($map: variables.$grid-breakpoints, $key: 'lg')) {
    .announcement-thumbnail-container {
      width: fit-content;

      .announcement-thumbnail {
        width: 378px;
      }
    }
  }

}

// component
.announcement-box {
  .mat-card-content {
    max-height: 423px;
    overflow: auto;

    p {
      margin-bottom: 0;
    }
  }

  .mat-card-avatar {
    border-radius: 6px;
  }

  &__header.mat-card-header {
    .mat-card-header-text {
      width: 100%;
      margin-right: 0;
      margin-left: 10px;
    }

    .mat-card-title {
      display: flex;
      margin-bottom: 0;
      width: 100%;

      .el-responsive {
        margin-bottom: 0;
        color: black;
        font-size: 14px;
      }

      p {
        margin: auto;
        font-size: 14px;
        margin-top: 10px;
        margin-right: 0;
      }
    }
  }

  &__avatar {
    background-size: cover;
  }

  &__content {
    color: hsla(231, 12%, 44%, 1);
  }

  &__card:hover {
    cursor: pointer;
    background-color: variables.$background-grey;
    border-radius: variables.$rounded-md;
  }

  .see-all {
    display: flex;
    place-content: center;
    color: #4F9573;
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    span {
      cursor: pointer;
    }
  }
}

.flexbox-wrap {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.desc-txt {
  line-height: 1.5;

  img {
    max-width: 100%;
    border-radius: variables.$rounded-md;
  }
}

.announcement-box {

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }
}