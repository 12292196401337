@use '../variables' as *;

.app-menu {
  position: relative;

  .dashboard-toolbar {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    z-index: 2;
    position: absolute;
    padding: 0;
    height: auto;
    // border-bottom: 1px solid $black-squeeze-color;

    &.blurred {
      background-color: $color-primary;
    }

    .dashboard__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0.5rem 1rem;
      min-height: 70px;
      width: 100%;

      h1 {
        margin: 0;
        padding: 0;
        font-weight: 400;
        flex: 1;
        margin-left: 32px;
      }

      .icon {
        background-repeat: no-repeat;
        background-position: center;
        color: #fff;
      }
    }

    .header-action {
      display: flex;
      align-items: center;

      .avatar {
        margin-left: 1em;

        .profile-menu-btn {
          border-radius: $rounded-sm;
          padding: 4px;
        }
      }
    }

    .dash-row {
      display: block;
      width: 100%;
      overflow: auto;
    }

    .dash-row + .dash-row {
      margin: 0rem 1rem;
    }
  }

  .mat-form-field-flex {
    width: 100%;
  }

  .no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    padding-right: 50px !important;
  }

  .mat-dialog-container {
    background: #f8f9fe;
    padding: 50px 80px !important;

    .mat-dialog-actions {
      button.mat-focus-indicator.mat-button.mat-button-base.mat-primary {
        color: #59595b;
      }

      button {
        background: #fff;
        padding: 5px 25px;
      }
    }
  }

  .example-sidenav .mat-drawer-inner-container {
    //overflow: hidden;
    // border-right: 1px solid $black-squeeze-color;
  }

  .app-container {
    height: 100vh;
    background: $black-squeeze-light;

    .example-sidenav {
      /* width */
      ::-webkit-scrollbar {
        width: 0px !important;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888 !important;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    }
  }

  .mat-list-base .mat-list-item.mat-list-item-with-avatar,
  .mat-list-base .mat-list-option.mat-list-item-with-avatar {
    height: 55px !important;
    display: flex;
    align-items: center;
    width: 100%;
  }

  mat-nav-list#list-nav {
    .active {
      background-color: rgba($color: $color-primary, $alpha: 0.15);
      border-radius: 0;

      &:not(.mat-expansion-panel-header) {
        &::after {
          content: '';
          width: 20px;
          height: 80%;
          background: $color-primary;
          border-radius: 50px;
          position: absolute;
          right: -17px;
        }
      }

      span,
      mat-icon,
      .mat-expansion-indicator::after {
        color: $color-primary !important;
        z-index: 1;
      }

      .mat-icon {
        &.svg-active {
          svg {
            filter: invert(41%) sepia(54%) saturate(2368%) hue-rotate(202deg) brightness(104%) contrast(92%);

            #G {
              opacity: 1;
            }
          }
        }
      }
    }

    mat-list-item {
      color: $scarpa-flow;
      .mat-list-item-content {
        width: 100%;

        .menu-item-title {
          padding-left: 20px;
        }
      }

      &.triangle {
        &::before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent currentColor;
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          color: $mercury-color;
        }

        &:hover {
          &::before {
            color: $aluminium;
          }
        }
      }

      &.active.triangle {
        &::before {
          color: rgba($color: $color-primary, $alpha: 0.5);
        }
      }

      &:hover {
        &:not(.active) {
          background: rgba($color: #000000, $alpha: 0.1);
        }
      }
    }

    //*Custom child nav style
    .mat-expansion-panel .mat-expansion-panel-body .expand {
      span {
        display: flex;
      }

      .mat-list-item-content {
        margin-left: 2em;
        .menu-item-title {
          padding-left: 12px;
        }
      }
    }

    .mat-expansion-panel {
      .active {
        svg {
          filter: invert(41%) sepia(54%) saturate(2368%) hue-rotate(202deg) brightness(104%) contrast(92%);
        }
      }
    }

    .mat-list-text {
      display: none !important;
    }

    // notification
    .mat-icon {
      &.dots::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 16px;
        left: 34px;
        background: #ff5d5d;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        transition: transform 200ms ease-in-out;
        transform: scale(0.6);
      }
    }
  }
}

.hover-profile + * .cdk-overlay-pane {
  .mat-menu-panel {
    border-radius: $rounded-sm;

    .profile-img {
      display: flex;
      justify-content: flex-start;
      width: 17em;
      align-items: center;
      border-radius: initial;
      height: 70px;
      min-height: 70px;
      border: none;

      &:hover {
        background-color: transparent !important;
      }

      .avatar-profile {
        width: 35px;
        height: 35px;
      }

      .name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .role {
        font-size: 12px;
        color: $scarpa-flow;
      }

      &:hover {
        background-color: $black-squeeze-color;
      }
    }
  }
}

.nav-mat-menu + * .cdk-overlay-pane {
  margin-left: 6em;

  .mat-menu-panel {
    overflow-x: hidden;
    border-radius: $rounded-sm;
    mat-nav-list {
      padding-top: 0;
      .active {
        background-color: rgba($color: $color-primary, $alpha: 0.15);
        border-radius: 0;

        &:not(.mat-expansion-panel-header) {
          &::after {
            content: '';
            width: 20px;
            height: 80%;
            background: $color-primary;
            border-radius: 50px;
            position: absolute;
            right: -17px;
            top: 10%;
          }
        }
      }
      .menu-item-title {
        font-size: 14px;
      }
    }
  }
}
