@use "../mixin/flex" as *;

.practice-exam,
.payment {
    .profile-container-mat-chip {
        @include flexbox();
        @include align-items(center);
        .profile-image {
            width: 25px;
            height: 25px;
            object-fit: cover;
            border-radius: 50%;
            margin: 8px 8px 8px 0;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .name {
            font-size: 12px;
        }
    }

    .profile-container-mat-chip-view {
        @include flexbox();
        @include align-items(center);
        // background: rgba(116, 116, 116, 0.1);
        /* Grey/10% */

        // border: 1px solid rgba(116, 116, 116, 0.1);
        // border-radius: 8px;
        // padding: 2.5px 7px;
        .profile-image {
            width: 25px;
            height: 25px;
            object-fit: cover;
            border-radius: 50%;
            margin: 8px 8px 8px 0;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .name {
            font-size: 12px;
        }
    }
}
