@use '../variables'as *;

.staff-wrapper,
.student-wrapper,
.view-form {
  .lecturer-profile {
    width: 79px;
    height: 79px;
    min-width: 79px;
    min-height: 79px;
  }

  .detail-form {
    .detail-text {
      .grid {
        padding: 16px 0;

        border-bottom: 1px solid $athens-gray-color;
        // &:not(:last-child, :nth-last-child(2)) {
        //   border-bottom: 1px solid $athens-gray-color;
        // }
      }

      label {
        color: $scarpa-flow;
      }
    }
  }

  .table-header {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.staff-wrapper {
  .margin-auto {
    margin: auto;
  }

  .btn-remove {
    margin-top: 8px;
  }

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    .mb-8 {
      margin-bottom: 8px !important;
    }

    .btn-remove {
      margin-top: 0;
    }

    .padding-top {
      padding-top: 15px;
    }
  }

  @media screen and (max-width: 1570px) and (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
    .box-wrapper {

      .box-border {
        border-bottom: 1px solid #F4F4F7;
        padding: 16px 0;

        .form-4-responsive {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }

  @media screen and (max-width: 1570px) {
    .box-wrapper {
      .box-border {
        border-bottom: 1px solid #F4F4F7;
        padding: 16px 0;
      }
    }

    .button-add-form {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .box-wrapper {
      .box-border {
        .form-4-responsive {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }
    }
  }

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
    .w-100 {
      margin-top: 8px;
    }
  }

}
