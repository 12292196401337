.search-box {
  &__select-option {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #fff;
    z-index: 1;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;

      .mat-form-field-flex {
        width: 100%;
        padding-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
      }
    }

    .mat-form-field-underline {
      bottom: 0;
    }

    .mat-form-field-infix {
      padding: 8px 0 !important;
      border-top: 0;
    }

    .mat-form-field-label-wrapper {
      padding-top: 0 !important;
    }
  }

}
