@use '../variables';

.mat-form-field {
  display: block !important;
  line-height: 2;

  &.mat-form-field-appearance-outline {

    /* input */
    .mat-form-field-infix {
      border-top: 0.35em solid transparent;
      padding: variables.$spacing-xs 0;
    }

    .mat-form-field-prefix {
      top: 0.5em;
    }

    .mat-form-field-suffix {
      top: 0.3em;
    }

    .mat-form-field-prefix {
      color: variables.$color-grey;
    }

    .mat-input-element:disabled {
      color: variables.$color-black;
    }

    .mat-select-disabled .mat-select-value {
      color: variables.$color-black;
    }

    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      min-width: 18px;
      //background-color: variables.$background-light-grey;
    }

    .mat-form-field-outline-start {
      border-radius: variables.$rounded-sm 0 0 variables.$rounded-sm;
    }

    .mat-form-field-outline-end {
      border-radius: 0 variables.$rounded-sm variables.$rounded-sm 0;
    }

    .mat-form-field-outline {
      color: variables.$black-squeeze-color;
    }

    /* subscript */
    .mat-form-field-subscript-wrapper {
      // margin-top: 0.3666666667em;
      margin-top: 0;
    }

    /* date picker */
    .mat-datepicker-toggle {
      color: variables.$color-primary;
    }

    /* select */
    .mat-select-arrow {
      opacity: 0;
    }

  }

  // Custom outline color on hover and focus
  &.mat-form-field-appearance-outline.mat-focused {
    &:not(.mat-form-field-disabled, .mat-form-field-invalid) {
      .mat-form-field-outline-thick {
        color: variables.$color-primary;
      }
    }
  }

  &.mat-form-field-appearance-outline {
    &:not(.mat-form-field-disabled, .mat-form-field-invalid) {
      .mat-form-field-flex {
        &:hover {
          .mat-form-field-outline {
            color: variables.$color-primary;
          }
        }
      }
    }
  }
}

.mat-form-field-type-mat-select {
  mat-icon {
    &:not(.material-icons) {
      width: 10px;
      height: 16px;
    }
  }
}

#pdfTable {
  input {
    background-color: inherit;
    height: 24px;
    font-size: 14px;
    font-family: 'Khmer';

    &:focus-visible {
      outline: none;
    }
  }
}
