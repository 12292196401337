.v-pagin {
    .mat-form-field-appearance-outline .mat-form-field-infix,
    .mat-form-field-appearance-legacy .mat-form-field-infix {
      border-top: 0.4em solid transparent !important;
    }
    button {
      &:hover {
        color: #fff;
      }
      &:disabled {
        color: #888888;
      }
    }
}
.v-pagin .mat-form-field {
  &:not(:disabled){
    background-color: white;
  }
  &.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 5px 0px 5px 0;
    .mat-select {
      line-height: 33px;
    }
  }
  .mat-form-field-suffix {
    top: 0.1em !important;
    font-size: 2em;
  }
  width: 110px;
  margin-left: 10px;
}
.v-pagin{
  &:hover {
    color: #fff !important;
  }
  .mat-select {
    margin: 4px 0 4px 4px;
    width: 110px;
    max-height: 40px;
    font-size: 15px;
    font-weight: normal;
    border-radius: 5px;
    padding: 0.25em 0.5em;
    border: 1px solid #dadedf;
  }
  .mat-select {
    position: relative;
    &:not(:disabled){
      background-color: white;
    }
    font-size: 16px;
    font-weight: normal;
    .mat-select-value {
      line-height: 2;
      overflow: unset;
    }
  }
}
