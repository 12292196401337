.question-bank {
    .question-bank-profile {
        width: 67px;
        height: 45px;
        border-radius: 6px;
    }

    .creation-form {
        .mat-select-trigger {
            .mat-chip {
                min-height: 28px;
            }
        }

        .add-answer-btn {
            margin-left: 68px;
        }
    }

    .view-image-group {
        &.mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
            height: 60px;
            display: flex;
            align-items: center;
            padding-left: 80px;
            padding-right: 15px;
        }

        img {
            position: absolute;
            left: 0;
            width: 67px;
            height: 45px;
            object-fit: cover;
            border-radius: 8px;
        }

        .file-size {
            vertical-align: super;
            color: rgba($color: #000000, $alpha: 0.5);
        }
    }

    .detail {
        .mat-radio-button {
            &.mat-radio-checked.mat-radio-disabled {
                .mat-radio-outer-circle {
                    border-color: #3f88f5;
                }

                .mat-radio-inner-circle {
                    background-color: #3f88f5;
                }
            }
        }

        .answer-row {
            .drag-btn {
                cursor: unset;
            }
        }
    }


    .question-bank-stepper {
        background-color: inherit;

        .mat-horizontal-content-container {
            padding: 0;
        }

        .mat-horizontal-stepper-header-container {
            width: 700px;
            margin: auto;
            margin-bottom: 16px;
        }

        .mat-horizontal-stepper-header {
            pointer-events: none;
            background-color: inherit;

            .mat-step-icon-selected,
            .mat-step-icon-state-edit {
                background-color: green;
            }
        }

        .doc-star {
            min-width: 24px;
            min-height: 24px;
            margin-right: 8px;
        }
    }
}

.answer-row {
    position: relative;
    width: 100%;
    min-height: 50px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: white;
    font-size: 14px;
    padding: 5px 5px 5px 36px;

    .answer-radio-button {
        &:not(:last-child) {
            margin-right: 0;
        }

        .mat-radio-label {
            margin-right: 8px;
        }
    }

    .drag-btn {
        position: absolute;
        left: 0;
        color: #EAF0F8;
        cursor: move;
    }

    .mat-form-field {
        width: 100%;

        .mat-form-field-wrapper {
            padding-bottom: 0;
            margin: 0;
        }
    }

    .delete-icon {
        min-width: 24px;
        min-height: 24px;
        margin-left: 32px;
        cursor: pointer;

        svg {
            path {
                stroke: rgba($color: #F53F3FCC, $alpha: 0.8)
            }
        }
    }

    .answer-name {
        width: 100%;
        min-height: 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // border: 1px solid #EDF2F9;
        border-radius: 8px;
        line-height: 2;
        text-align-last: left;
        // padding: 0 16px;

        mjx-container[jax="CHTML"][display="true"] {
            margin: 0.5em 0;
        }
    }

    .answer-profile-image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: auto;
        border-radius: 8px;
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.answer-radio-group.cdk-drop-list-dragging .answer-radio-button:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.answer-placeholder {
    width: 100%;
    background: #ccc;
    border: dotted 3px #999;
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

///////////////////////////////////////

.stepper-footer {
    display: flex;
}

.manage-question-stepper {
    .manager-side {
        overflow: auto;
        max-height: 600px;

        &__header {
            display: flex;
            align-items: center;
            place-content: center space-between;
            margin-bottom: 16px;
        }

        &__title {
            color: #53535F;
            font-size: 18px;
            font-weight: 600;
        }

        &__card {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: 44px;
            border-radius: 8px;
            color: rgba($color: #000000, $alpha: 0.7);

            // &:hover {
            //     cursor: pointer;
            //     background-color: rgba($color: #000000, $alpha: 0.025);

            //     .manager-side__remove-btn {
            //         display: block;
            //     }
            // }
        }

        &__category {
            .group-btn {
                .btn-sm {
                    margin-right: 0;
                }
            }
        }

        &__child {
            &:hover {
                cursor: pointer;
                background-color: rgba($color: #000000, $alpha: 0.025);

                .manager-side__remove-btn {
                    display: block;
                }
            }
        }

        &__question {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 10px 8px;

            .name {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                line-height: 1.8;

                mjx-container[jax=CHTML][display=true] {
                    margin: 0 !important;
                    display: inline !important;
                }
            }
        }

        &__remove-btn-container {
            margin: 10px 8px;
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
        }

        &__remove-btn {
            display: none;
            margin-left: auto;
            min-width: 24px;
            min-height: 24px;

            svg {
                path {
                    stroke: rgba($color: #F53F3FCC, $alpha: 0.8)
                }
            }
        }

        &__divider {
            margin: 8px 0;
        }

        &__selected {
            &.manager-side__card {
                background-color: rgba($color: #3f88f5, $alpha: 0.1);
                color: black;
                font-weight: 600;
            }
        }

        &__invalid {
            &.manager-side__card {
                border: 2px solid #fc7e7e;
                transition: opacity 100ms cubic-bezier(0.25, 0.8, 0.25, 1);
            }
        }
    }

    .detail-side {
        overflow: auto;
        max-height: 600px;

        .type-form-field {
            width: 95px;

            &.mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-end {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .type-selection {
                .mat-select-trigger {
                    height: 100%;

                    .selected-icon {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        min-height: 20px;
                        position: absolute;
                        top: -14px;
                    }
                }
            }
        }

        .name-form-field {
            // min-width: 200px;

            &.mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-start {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }

        .type-upload-image-container {
            width: 100%;

            &.invalid {
                .form-control {
                    border: 2px solid currentColor;
                    color: #fc7e7e;
                    opacity: 1;
                }
            }

            .form-control {
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
            }
        }

        .matching-form {
            .add-answer-btn {
                margin-left: 0;
                margin-top: 2em;
            }
        }

        .matching-ol {
            margin: 0;
            padding-left: 20px;
        }
    }

    .empty-question-thumbnail {
        width: 160px;
        height: 120px;
        min-width: 160px;
        min-height: 120px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 60px auto 16px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(#ccc7c7, 0.9);
        border-radius: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #bbbbbb;
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes hideMe {
    0% {
        opacity: 1;
    }

    99.99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.preview-info-stepper {
    .detail-form {
        .box {
            display: flex;
            align-items: center;

            &.qualification-title {
                flex-direction: row;
                flex-wrap: wrap;
            }

            .clip-span {
                background: rgba(63, 136, 245, 0.1);
                display: flex;
                align-items: center;
                height: 1px;
                min-height: 28px;
                border-radius: 10px;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-right: 8px;
                padding-left: 12px;
                margin: 4px;
            }
        }
    }

    .creation-form {
        .mat-radio-group {
            padding-bottom: 6px;
        }
    }

    .question-list {
        .name {
            mjx-container[jax=CHTML][display=true] {
                margin: 0 !important;
                display: inline !important;
            }
        }
    }

    .matching-ol {
        margin: 0;
        padding-left: 16px;
    }

    .grid-header {
        color: #53535F;
        font-weight: 600;
    }
}

.question-bank {
    .detail-side {
        position: relative;

        &__loading {
            z-index: 1;
            height: 100%;
            width: 100%;
            display: grid;
            place-items: center;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #3333;
            border-radius: 8px;
        }

        &__syncing {
            position: absolute;
            right: 25px;
            color: #249f61;
            font-size: 14px;
            font-weight: normal;

            .sync-icon {
                -webkit-animation: spin 4s linear infinite;
                -moz-animation: spin 4s linear infinite;
                animation: spin 4s linear infinite;
            }

            .saved {
                animation: hideMe 5s forwards;
            }
        }
    }
}

.answer-row {
    &.matching-row {
        padding-left: 5px;

        .group-form-field {
            width: 100%;
        }
    }
}