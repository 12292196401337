@use "../variables" as *;

.qualification-title-wrapper,
.cdk-drag-preview {
  .table-header {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .unit-type {
    max-width: 115px;

    .mat-form-field-wrapper {
      padding: 0;
    }
  }

  .mat-form-field-suffix {
    button {
      transform: translate(11px, -4px);
      color: $silver-chalice;
      border-radius: 0 !important;
      background-color: $black-squeeze-color;
      border: 1px solid $black-squeeze-color;
      border-top-right-radius: $rounded-sm !important;
      border-bottom-right-radius: $rounded-sm !important;
      font-weight: normal;
      pointer-events: none;
    }
  }

  .invalid {
    .mat-form-field.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: #fc7e7e !important;
        opacity: 1 !important;
      }
    }
  }

  .mat-form-field-disabled {
    .mat-form-field-outline-end,
    .mat-form-field-outline-start {
      background-color: $alabaster;
    }

    .mat-select-value,
    input,
    button,
    .mat-select-arrow-wrapper,
    .mat-form-field-flex {
      cursor: not-allowed;
    }
  }
}
