/* color */
$color-primary: #3f88f5;
$color-secondary: #63677d;
$color-grey: #b4b4b4;
$color-white: #ffffff;
$color-red: #ff0000;
$color-black: #000000;
$color-thin-grey: #E8E9ED;
$color-warn: #FFC42A;
$color-light-red: #ff6464;
$color-short-course: #39CADA;
$color-long-course: #194F82;
$color-required: #fc7e7e;

$silver-chalice: #A0A0A0;
$fade-green: #4f957329;
$alabaster: #F9F9F9;
$athens-gray-color: #F4F4F7;
$mercury-color: #E6E6E6;
$waterloo-color:#757B97;
$iron-color: #E7E7E8;
$tapestry-color: #AD5389;
$black-squeeze-color: #EDF2F9;
$black-squeeze-light: #f8fafd;
$scarpa-flow: #53535F;
$harlequin: #1FDB00;
$sea-buckthorn: #F89F37;
$aluminium: #A6A7AE;

//attendance status
$salem: #0FA958;
$bittersweet: #FF755F;
$deluge: #76629F;

/* border color */
$border-grey: #E8EAEF;
$border-red: red;

/* background color */
$background-grey: #f5f5f5;
$background-light-grey: $black-squeeze-light;

/* linear gradient */
$linear-gradient-primary: linear-gradient(270deg, $color-primary 0%, #134e5e 100%);
$linear-light-gradient-primary: linear-gradient(270deg, #237a576b 0%, #134e5e33 100%);

/* rounded (like: border-radius) */
$rounded-sm: 8px;
$rounded-md: 10px;
$rounded-lg: 15px;
$rounded-xl: 20px;
$rounded-2xl: 30px;
$rounded-full: 50%;

/* spacing (like: margin, padding) */
$spacing-xs: 6px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 30px;

/* scss-docs-start grid-breakpoints */
$grid-breakpoints: (
  /* Mobile */
  'xs': 0,
  /* Tablet */
  'sm': 600px,
  /* Small Laptop */
  'md': 960px,
  /* Large Laptop */
  'lg': 1280px,
  /* Desktop */
  'xl': 1920px) !default;

/* scss edges */
$edges: ('t': top,
  'r': right,
  'b': bottom,
  'l': left) !default;

$edge-axis: x,
y !default;

/* scss borders */
$border-styles: solid,
dashed,
dotted,
double,
none;

/*scss layout */
$layout-gap: 12px;

/* if not pass any value mean value = 1 */
@mixin line-clamp($value: 1) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: $value;
}
