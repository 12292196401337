@use '../variables';

.timetable {
  .table-schedule {
    table {
      thead {
        position: sticky;
        top: 0;
        z-index: 2;
      }

      tbody {
        tr {
          height: 40px;

          &:nth-of-type(odd) {

            td:first-child {
              border-bottom: none;
            }
          }

          td:first-child {
            text-align: center;
          }
        }
      }

      tr {
        th.mat-header-cell {
          min-width: 100px;
          text-align: center;
          color: variables.$color-white;
          background-color: variables.$color-primary;
          font-weight: bold;
          padding: 0;

          &:first-of-type {
            border-left: none;
          }

          &:last-of-type {
            border-right: none;
          }

          &:first-child {
            width: 6%;
          }

          &:not(:first-child) {
            width: 13.42857%;
          }

          &:not(:first-child) {
            border-bottom: none;
          }
        }

        &:not(:last-child) .mat-cell {
          text-align: center;
          height: 40px;
          width: 65px;
          padding: 0;
          white-space: nowrap;

          &.schedule-data-wrapper {
            position: relative;
          }

          .schedule-data {
            overflow: hidden;
            border-radius: 3px;
            color: variables.$color-black;
            line-height: 1.9;
            display: flex;
            flex-direction: column;
            width: 100%;
            position: absolute;
            top: 15px;
            z-index: 1;
            height: inherit;
            justify-content: flex-start;

            &:hover {
              overflow: auto;
            }

            .content {
              margin-left: 10px;

              span {
                margin: 0 4px;
              }
            }
          }

          .custom-bg {
            background-color: white;
          }

          .td-scheduled {
            transition: all ease-in-out 300ms;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .v-line {
              width: 5px;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              border-radius: variables.$rounded-sm;
            }
          }

          .td-info {
            flex-direction: column;
            color: variables.$color-white;
            justify-content: flex-end;
          }
        }

        td:not(:first-child) {
          border-left: 1px solid variables.$border-grey;

          &:last-child {
            border-right: 1px solid variables.$border-grey;
          }
        }
      }
    }
  }
}

.content-box .action {
  cursor: pointer;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #fff;
  position: absolute;
  margin-left: 0px !important;
  top: 0;
}

.content-box {
  // height: 100%;
  // overflow: hidden;

  &:hover {
    .action {
      background-color: rgba($color: variables.$color-primary, $alpha: 0.7450980392156863);
      visibility: visible;

      button {
        margin: 4px;
        border-color: white;

        &:hover {
          background-color: rgba($color: white, $alpha: 0.3);
        }
      }
    }
  }
}
