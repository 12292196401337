@use '../mixin/flex' as *;
@use '../variables' as var;

$colors: (
    #F6D215,
    #6CA6FF,
    #15DBF6,
    #FC5F72,
    #1FDB00
);

// filter
.report-filter {
    form {
        display: grid;
        gap: 16px;
        align-items: center;

        .print-btn {
            margin-left: auto;
        }
    }

    .mat-form-field {

        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
            min-width: 18px;
            background-color: #fff;

        }

        .mat-form-field-wrapper {
            padding: 0;
        }
    }

    @media screen and (min-width: (map-get($map: var.$grid-breakpoints, $key: "sm"))) {
        form {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media screen and (min-width: (map-get($map: var.$grid-breakpoints, $key: "md"))) {
        form {
            grid-template-columns: repeat(4, minmax(0, 1fr));

            .generate-report-btn {
                width: fit-content;
            }
        }
    }
}

// report
.report {

    &__container {
        min-height: 400px;
        @include flexbox;
        @include flex-direction(column);
        align-items: center;
        place-content: center;
        background-color: var.$background-light-grey;
    }

    &__empty {
        @include flexbox;
        @include flex-direction(column);
        align-items: center;
    }

    &__dougnut-chart {
        .doughnut-chart {
            margin: unset;
        }
    }

    &__total-payments {
        .title-label {
            font-size: 16px;
        }

        .total {
            position: relative;

            .currency {
                position: absolute;
                top: -4px;
                left: 0;
                font-size: 16px;
            }

            .amount {
                margin-left: 14px;
                font-size: 26px;
                font-weight: 500;
            }
        }
    }

    .header-label {
        &.total-doughnut {
            margin-bottom: -16px;
        }
    }

}

.app-report {
    width: 100%;

    .register-by-gender,
    .assessment-result {
        align-items: center;
    }

    .chart-container {
        &.average-candidate-age {
            align-items: flex-start;

            .doughnut-legend-container {
                width: 100%;

                .row {
                    align-items: flex-end;
                    color: rgba(0, 0, 0, 0.65);

                    .dot {
                        height: 10px;
                        width: 10px;
                        min-height: 10px;
                        min-width: 10px;
                        margin-right: 10px;
                        border-radius: 50%;
                    }

                    .progress-bar-container {
                        display: flex;
                        flex-direction: column;

                        .percentage {
                            margin-left: auto;
                            color: rgba($color: var.$color-primary, $alpha: 0.9);
                            font-size: 12px;
                        }
                    }

                    .mat-progress-bar {
                        height: 8px;
                        border-radius: 4px;

                        @each $color in $colors {
                            &.color-#{unquote("\\" + $color)} {

                                .mat-progress-bar-buffer {
                                    background-color: #ecf3fe;
                                }

                                .mat-progress-bar-fill::after {
                                    background-color: $color;
                                }
                            }
                        }
                    }

                }

            }
        }
    }

    .app-header {
        .sub-header {
            margin-left: auto;
            margin-bottom: 16px;

            .actions {
                .mat-form-field-appearance-outline .mat-form-field-wrapper {
                    padding: 0;
                }

                .assessment-center-form-field {
                    &.mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix {
                        top: 0.25em;
                    }

                    .assessment-icon {
                        width: 20px;
                        min-width: 20px;
                        height: 20px;
                        min-height: 20px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}