.upload-image-group {
    position: relative;
    display: table;
    border-collapse: separate;

    .mat-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
        }

        &.mat-form-field-appearance-outline {
            &:hover {
                cursor: not-allowed;
            }

            .mat-form-field-outline-end {
                border-radius: 0;
            }
        }
    }

    .input-group-btn {
        display: table-cell;
        width: 1%;
        position: relative;
        white-space: nowrap;
        vertical-align: middle;

        .browse {
            display: inline-block;
            padding: 10px 12px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-image: none;
            border: 1px solid transparent;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            font-family: inherit;
        }
    }
}

.remove-file {
    cursor: pointer;

    .mat-icon {
        position: absolute;
        top: -8px;
        right: -10px;
        background: red;
        color: white;
        border-radius: 50%;
        padding: 2px;
        font-size: 20px;
    }
}

.upload-image-group1 {
    .input-group {
        flex-wrap: nowrap;
        position: relative;
        display: flex;
        align-items: stretch;
        width: 100%;

        .form-outline {
            width: 100%;

            .form-control {
                border: 1px solid;
                min-height: 40px;
                width: 100%;
                padding: 6px 0.75em;
                border-radius: 8px 0 0 8px;
                border-color: #EDF2F9;
                font-size: inherit;
                font-family: inherit;
                background: transparent;
                color: currentColor;
                outline-color: #3f88f5;
            }
        }

        .browse {
            width: 100px;
            min-width: 100px;
            padding: 0 12px;
            font-size: inherit;
            font-family: inherit;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            cursor: pointer;
        }
    }
}