@use "../variables";

.creation-form,
.detail-form {
  background-color: variables.$color-white;
  padding: 25px;
  border: 1px solid variables.$black-squeeze-color;
  border-radius: variables.$rounded-sm;
  .header-label {
    color: variables.$scarpa-flow;
    margin-bottom: variables.$spacing-md;
    font-size: 18px;
    font-weight: 600;
  }

  .section-label {
    color: variables.$scarpa-flow;
    margin-bottom: variables.$spacing-md;
    font-size: 14px;
    font-weight: bold;
  }

  .border-bottom {
    border-bottom: 1px solid rgb(204, 204, 204);
    margin: 16px 0;
  }
  .group-action {
    text-align-last: end;
  }

  .value {
    color: variables.$scarpa-flow;
    font-weight: 600;
    width: 100%;

    &.name {
      font-size: 22px;

      .position {
        color: variables.$color-primary;
        font-weight: 500;
      }
    }
  }

  .phone-number {
    display: flex;
    align-items: center;

    svg {
      vertical-align: top;
    }
  }

  .profile-with-name {
    display: flex;
    align-items: center;
  }

  .right-side {
    .resume-file,
    .cv {
      display: flex;
      align-items: center;
    }

    .resume-file {
      .download-btn {
        margin-left: auto;
      }
    }
  }

  .invalid {
    color: variables.$color-required;
  }

  .required::after {
    content: "*";
    color: variables.$color-required;
    vertical-align: middle;
    margin-left: 2px;
  }

  .require {
    span {
      color: variables.$color-required;
    }
  }

  .mat-chip {
    color: variables.$color-primary;
    background-color: rgba(variables.$color-primary, 0.1);
    margin: 4px 3px 3px 3px;
    min-height: 29px;
  }

  .img-center {
    .size-image {
      max-width: 300px;
      height: 200px;
      img {
        width: 100%;
      }
    }
  }

  .mat-tab-group {
    .tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .mat-tab-label {
      min-width: 140px;
    }

    .mat-ink-bar {
      min-width: 135px !important;
    }

    .list-certificate {
      border-bottom: 1px solid rgb(223, 223, 223);
      .group-bt {
        float: right;
        .btn-cancel {
          padding: 25px;
          margin-right: 16px;
          border: 1px solid rgb(223, 223, 223);
        }
      }

      .update {
        height: 17vh;

        #year {
          position: relative;
          background-color: #fff;
          padding: 8px;
          bottom: 5px;
          left: 7px;
          font-weight: 500;
          font-size: 14px;
          border-radius: 10px;
        }
      }
    }
  }

  .mat-tab-group[mat-stretch-tabs] > .mat-tab-header .mat-tab-label {
    flex-basis: 0;
    flex-grow: 0;
  }

  @media screen and (min-width: 1280px) {
    .left-side {
      padding-right: variables.$spacing-lg;
      border-right-width: 1px;
      border-right-color: rgba(0, 0, 0, 0.05);
    }

    .right-side {
      padding-left: variables.$spacing-md;

      .header-label {
        display: block !important;
        //visibility: hidden;
      }
    }
  }

  .info {
    .value {
      padding-left: 5px;
      color: #3f87f5;
      font-weight: 600;
    }
  }

  .tb-custom {
    width: 100%;
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    td,
    th {
      border-bottom: 1px solid #ddd;
      padding: 8px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  hr {
    border-bottom: 1px solid #ddd;
    margin: 16px 0;
  }

  .group-picture-name {
    display: flex;
    align-items: center;
  }

  .rejected-box {
    background: #ffebec;
    border-radius: 10px;
    padding: 12px;

    .msg {
      span {
        display: flex;
        align-items: center;
        gap: 8px;

        .mat-icon {
          svg {
            path:first-child {
              fill: #f53f3f;
            }
          }
        }

        &.info {
          padding-left: 33px;
          ul {
            color: red;
            list-style: disc;
            margin-block: 0;
            li {
              .txt {
                display: flex;
                align-items: center;
                gap: 12px;
                .reason {
                  color: #53535f;
                }
              }
            }
          }
        }
      }
    }
  }

  // end of form
}

.readonly-form {
  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: transparent;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.required-form {
  label {
    span {
      color: variables.$color-required;
    }
  }
}

.profile_box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  height: 100px;

  background: #f9f9f9;
  border: 1px dashed #e8e9ed;
  border-radius: 10px;

  .add_user {
    width: 50px;
    height: 50px;
  }

  p {
    color: #e8e9ed;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
  }

  &:hover {
    cursor: pointer;
  }

  .close {
    position: absolute;
    right: -10px;
    top: -10px;
    display: none;
  }

  &.image:hover {
    .close {
      display: block;
      cursor: pointer;
    }
  }
}

.error_outline {
  .mat-icon {
    color: variables.$color-primary;
    position: relative;
    top: 6px;
    cursor: pointer;
  }
}

.create-form {
  .header-label {
    color: variables.$scarpa-flow;
    margin-bottom: variables.$spacing-md;
    font-size: 18px;
    font-weight: 600;
  }
}

.input-name {
  position: relative;

  .message {
    position: absolute;
    .message-content {
      position: absolute;
      font-size: 12px;
      border-radius: 5px;
      width: 200px;
      right: 0;
      left: 48px;
      bottom: -127px;
      background-color: #ffffff;
      z-index: 1;
      box-shadow: 0 2px 5px #e6e6e6;
      box-sizing: border-box;
      border: 1px solid #e8eaef;
    }
    .creating-username {
      padding-left: 10px;
      font-weight: 500;
      background-color: #ffffff;
    }
  }
  .question_mark {
    position: absolute;
    bottom: -2px;

    .help {
      font-size: 20px;
      color: variables.$color-grey;
    }

    .help:hover {
      color: rgb(107, 107, 107);
    }
  }
}
