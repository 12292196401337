.mat-menu-item {
    .mat-icon {
        width: 20px;
        height: 20px;
        &.black {
            svg path {
                fill: rgba(0, 0, 0, 0.54)
            }
        }
    }
}