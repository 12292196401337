@use '../variables' as *;

/* custom mat table */
table.mat-table {
  width: 100%;
  border-radius: $rounded-md;

  thead {
    //background-color: #f5f5f5;
  }

  tr {
    &:last-child {
      // td {
      //   &:first-child {
      //     border-bottom-left-radius: $rounded-md;
      //   }

      //   &:last-child {
      //     border-bottom-right-radius: $rounded-md;
      //   }
      // }
    }

    &:first-child {
      // th {
      //   &:first-child {
      //     border-top-left-radius: $rounded-md;
      //   }

      //   &:last-child {
      //     border-top-right-radius: $rounded-md;
      //   }
      // }
    }

    th {
      // border-top: 1px solid $border-grey;
      font-size: 14px;
      text-transform: capitalize;
      font-weight: bold;
      color: $scarpa-flow;
    }

    th,
    td {
      // text-align: center;
      padding: 0 $spacing-sm;
      border-bottom-color: $black-squeeze-color;

      .mat-radio-group {
        display: flex;
        padding: 0;
      }

      // &:first-of-type {
      //   border-left: 1px solid $border-grey;
      // }

      // &:last-of-type {
      //   border-right: 1px solid $border-grey;
      // }
    }

    &.mat-row:last-child {
      td {
        border-bottom-color: $border-grey;
      }
    }
  }
}

// menu item
.menu-item {
  text-decoration: none;
}
