@use '../variables';

.home {
  max-width: 2000px;
  margin: 0 auto;
  background-color: inherit;

  .student-chart {
    height: 409px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
  }

  .stat-detail {
    height: 100%;
    display: flex;
    align-items: center;

    div {
      margin-left: 1em;
    }

    h1 {
      font-weight: bold;
    }

    h1,
    p {
      color: #24292f;
      margin: 0;
    }
  }

  .flex-header {
    .title {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.05rem;
    }

    .academic-filter {
      margin-left: 16px;

      &.mat-select {
        height: 44px;
        border: 1px solid #e7e7e7;


        &.academic-filter {
          line-height: 32px;
          min-width: 200px;
          font-size: 15px;
          font-weight: 400;
          border-radius: variables.$rounded-md;
          background-color: #fff;
          padding: 0.25em 0.5em;
        }

        mat-select-trigger {
          display: flex;
          align-items: center;

          .mat-icon {
            margin-right: 6px;
            color: variables.$color-secondary;
          }
        }
      }

    }
  }

  .mobile-filter {
    .academic-filter {

      &.mat-select {
        height: 44px;
        border: 1px solid #e7e7e7;


        &.academic-filter {
          line-height: 32px;
          min-width: 200px;
          font-size: 15px;
          font-weight: 400;
          border-radius: variables.$rounded-sm;
          background-color: #fff;
          padding: 0.25em 0.5em;
        }

        mat-select-trigger {
          display: flex;
          align-items: center;

          .mat-icon {
            margin-right: 6px;
            color: variables.$color-secondary;
          }
        }
      }

    }
  }
}

.title-and-more {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .mat-card-header-text .mat-card-title {
    margin-bottom: 0;

    h1,
    h2,
    h3 {
      margin-bottom: 0;
    }
  }

  .mat-icon-button {
    &:hover {
      background-color: rgba(81, 78, 134, 0.1);
    }

    line-height: normal;
    min-width: auto;
    height: 35px;
    width: 35px;

    .mat-icon {
      height: auto;
      width: auto;
      line-height: normal;
      font-size: 21px;
    }
  }
}
