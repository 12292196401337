@use '../mixin/border' as border;

.dashboard {
    .sub-total-card {
        background-color: white;

        .total {
            font-size: 22px;
        }
    }
}