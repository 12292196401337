@use "../variables" as *;

.exam-evaluation-wrapper {
    .box {
        border: 1px solid #edf2f9;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.6em 0.75em 0.6em 0.75em;

        .mat-icon {
            width: 10px;
        }

        // &:hover {
        //     cursor: pointer;
        // }

        .group-picture-name {
            display: flex;
            align-items: center;
            gap: 12px;
            .profile-picture-list {
                margin: 0;
                width: 28px;
                height: 28px;
            }
        }
    }

    .exam-info {
        .value {
            padding-left: 5px;
            color: #3f87f5;
            font-weight: 600;
        }
    }

    .tb-skill {
        width: 100%;
        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
        }

        td,
        th {
            border-bottom: 1px solid #ddd;
            padding: 8px;
        }

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    }

    .status-fill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        height: 32px;

        .line-height-0 {
            line-height: 0;
        }

        &.active,
        &.passed,
        &.pending,
        &.incomplete,
        &.pretest,
        &.qualification_test,
        &.progress,
        &.inactive,
        &.failed,
        &.practice,
        &.payment {
            border-radius: 50px !important;
        }

        &.active,
        &.passed {
            color: $harlequin;
            border: 1px solid $harlequin;
        }

        &.pending,
        &.incomplete,
        &.pretest,
        &.qualification_test,
        &.progress,
        &.practice,
        &.payment {
            color: $sea-buckthorn;
            border: 1px solid $sea-buckthorn;
        }

        &.inactive,
        &.failed {
            color: $color-light-red;
            border: 1px solid $color-light-red;
        }
    }
}
