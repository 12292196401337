@use '../variables'as *;

.snackbar-custom {
  color: #fff;
  font-weight: 500;

  .snackbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .snackbar-message {
    display: flex;
    align-items: center;

    mat-icon {
      margin-right: 5px;
    }
  }

  #snackbar-duration {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mat-progress-bar.mat-warn {
    .mat-progress-bar-fill {
      &::after {
        background-color: $color-white;
      }
    }
  }

}

.snackbar-custom.snackbar-success {
  background-color: #4CAF50;
  .mat-progress-bar.mat-warn {
    .mat-progress-bar-buffer {
      background-color: #4CAF50;
    }
  }
}

.snackbar-custom.snackbar-error {
  background-color: #F44336;
  .mat-progress-bar.mat-warn {
    .mat-progress-bar-buffer {
      background-color: #F44336;
    }
  }
}

.cdk-global-overlay-wrapper+.snackbar-custom {
  pointer-events: visible;
}
