@use "../variables";

.mat-dialog-container {
  border-radius: variables.$rounded-sm !important;
  box-shadow: none;

  .app-content {
    padding: 0;
    .app-title {
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;

      .title {
        margin-left: 0;
      }

      .btn-sm {
        transform: translateY(-10px);
        background-color: rgba(28, 28, 28, 0.05);
        position: absolute;
        right: 0;

        mat-icon {
          width: 18px;
          height: 18px;
          line-height: 0;
        }
      }
    }
  }

  .mat-dialog-actions {
    padding: 20px 0 !important;
  }
}

// image display
.transparent .mat-dialog-container {
  background: transparent;
  box-shadow: none;
  position: relative;
  padding: 0px;
  border-radius: 0px;
  overflow: inherit !important;
  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(50%, -50%);
  }

  .close-icon {
    transition: 1s ease-in-out;
  }

  .close-icon:hover {
    transform: rotate(180deg);
  }
}

.backdropBackground {
  background-color: #000000;
  opacity: 0.7 !important;
}
