@use '../variables'as *;

.exam-date-wrapper {
  mat-chip-list {
    mat-icon {
      position: relative;
      opacity: 1 !important;

      svg {
        position: absolute;
      }
    }

    .mat-chip.mat-standard-chip {
      background-color: rgba($color: $color-primary, $alpha: 0.15);
      color: $color-primary;
      border-radius: $rounded-sm;
    }

    .mat-chip.mat-standard-chip.mat-chip-error {
      background-color: rgba($color: $color-light-red, $alpha: 0.15);
      color: $color-light-red;
    }
  }

  .disabled-button-list {
    flex-wrap: wrap;
    gap: $spacing-sm;

    button {
      background-color: $athens-gray-color;
      border-radius: $rounded-sm;

      mat-icon {
        height: 16px;
        width: 16px;
        line-height: 0;
      }
    }
  }

  .image-list {
    // border: 1px solid $black-squeeze-color;
    border-radius: $rounded-sm;
    flex-wrap: wrap;

    .relative-div {
      position: relative;

      .btn-remove {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: $color-light-red;
        border-radius: 50%;
        color: white;
        cursor: pointer;
      }

      .image-cover {
        border: 1px dashed $black-squeeze-color;
        border-radius: $rounded-sm;
        cursor: pointer;

        width: 100px;
        height: 125px;
        overflow: hidden;
        display: grid;
        place-items: center;

        img {
          width: inherit;
        }
      }

      .image-default {
        img {
          width: unset;
        }
      }
    }


  }
}

.images-upload-drag {
  .invalid .drop-zone {
    border-color: $color-light-red;
  }

  .note {
    color: #53535F66;
  }

  .drop-zone {

    &.fileover {
      border: 2px dashed $color-primary;
    }

    .icon-upload {
      font-size: 80px;
      margin-top: 10px;
      // margin-bottom: 10px;
      user-select: none;
      color: $color-secondary;
    }

    .btn-browse {
      color: $color-primary;
      text-decoration: underline;
      cursor: pointer;
    }

    p {
      margin-top: 0;
    }

    .file-name {
      overflow: hidden;
      text-overflow: ellipsis;
      // padding: 0 10px;
    }
  }

  .content-thumbnail {
    width: 30px;
    height: 32px;
    border-radius: 6px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    margin: {
      left: auto;
      right: auto;
    }

    &.video,
    &.file {
      height: 150px;
      width: 150px;
      cursor: pointer;
      border-radius: 100%;
    }
  }

  .dragCursor {
    cursor: move;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

    background-color: white;
  }

  .cdk-drag-preview .mat-row {
    padding: 0 14px !important;
  }

  .cdk-drag-placeholder {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background: #ccc !important;
      border: dotted 3px #999;
      min-height: 60px;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .file-content {
    position: relative;
    display: inline-block;

    &:hover {
      .clear-btn {
        display: block;
      }
    }

    .clear-btn {
      display: none;
      position: absolute;
      right: 0;
      transform: translate(0);
      background-color: $color-light-red;
      color: white;
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid;
      box-sizing: content-box;
    }
  }

  .browse-file {
    cursor: pointer;
  }

  content-required {
    border-color: $color-light-red;
  }

  .reorder-btn-container {
    justify-content: space-between;
  }
}

.flex-1 {
  flex: 1
}
