$icon-size: 20;

.mat-list-base .mat-list-item {
    font-size: 16px;

    // font-weight: 600;
    .mat-list-icon,
    .mat-list-base .mat-list-option .mat-list-icon {
        width: $icon-size * 1px !important;
        height: $icon-size * 1px !important;
        font-size: $icon-size * 1px !important;

        padding: 0 !important;
    }
}
