@use '../mixin/flex' as *;
$table-data-height: 400;

@mixin print-style {
  p {
    margin-bottom: 0;
  }

  .align-center {
    text-align: center !important;
  }

  .align-left {
    text-align: left !important;
  }

  .align-right {
    text-align: right !important;
  }

  .flex-1 {
    flex: 1;
  }

  .bg-white {
    background-color: #fff !important;
  }

  .width-full {
    width: 100%;
  }

  .margin-left-auto {
    margin-left: auto;
  }

  .place-content-center {
    place-content: center;
  }

  .line-height-normal {
    line-height: normal;
  }

  .profile-image {
    position: absolute;
    z-index: 2;
    width: inherit;
    place-content: center;
    display: grid;
    transform: translate(-4.34px, -117.5px);
    bottom: 0;

    .profile-thumbnail {
      width: 106px;
      height: 122px;
      object-fit: contain;
      // border-radius: 10px;
    }
  }

  .qr-container {
    position: absolute;
    bottom: 5em;
    left: 5.5em;

    qrcode {
      canvas {
        opacity: 0.75;
      }
    }
  }

  .khmer {
    p {
      font-family: 'Moullight';
      font-size: 14px;
      // font-weight: bold;
      color: rgb(35 32 33);
    }
  }

  .english {
    p {
      font-family: 'Times New Roman';
      font-size: 16px;
      color: rgb(35 32 33);
      font-weight: 600;
    }
  }

  @for $i from 0 through 1000 {
    .margin-left-#{$i} {
      margin-left: $i + px;
    }

    .margin-right-#{$i} {
      margin-right: $i + px;
    }

    .margin-top-#{$i} {
      margin-top: $i + px;
    }

    .margin-bottom-#{$i} {
      margin-bottom: $i + px;
    }
  }

  @for $i from 0 through 1000 {
    .padding-left-#{$i} {
      padding-left: $i + px;
    }

    .padding-right-#{$i} {
      padding-right: $i + px;
    }

    .padding-bottom-#{$i} {
      padding-bottom: $i + px;
    }

    .padding-top-#{$i} {
      padding-top: $i + px;
    }
  }

  @for $i from 0 through 1000 {
    .width-#{$i} {
      width: $i + px;
    }
  }

  @for $i from 0 through 1000 {
    .translate-y-#{$i} {
      transform: translateY($i + px);
    }
    .translate-x-#{$i} {
      transform: translateX($i + px);
    }
    .translate_y-#{$i} {
      transform: translateY(-$i + px);
    }
    .translate_x-#{$i} {
      transform: translateX(-$i + px);
    }
  }
}

.student-certificate-detail,
.create-issue-certificate {
  @include flexbox;
  @include flex-direction(column);

  .template {
    position: relative;
    width: 297mm;
    height: 210mm;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    &.certificate {
      height: auto;
    }

    .template-image {
      width: 100%;
      height: 100%;

      &.back-overlay-placeholder {
        position: absolute;
        padding: 128px 112px 70px;

        .back-table-container {
          // for loop class count > 11
          @for $i from 0 to 25 {
            // class name: count-[12,13,...]
            &.count-#{$i} {
              table.mat-table {
                tr.mat-header-row,
                tr.mat-row,
                tr.mat-footer-row {
                  // scale row height by row count
                  height: calc(600 / $i) + px;

                  @if ($i > 13) {
                    line-height: 1.15;
                  }

                  @if ($i > 19) {
                    line-height: 13.2px;
                  }
                }

                // tr {
                //   @if ($i > 15) {
                //     td {
                //       &.mat-cell {
                //         &:not(.mat-column-groupHeader) {
                //           font-size: 12px;
                //         }
                //       }

                //       &.mat-column-groupHeader {
                //         font-size: 14px;
                //       }
                //     }

                //     th {
                //       font-size: 14px;
                //     }
                //   }
                // }
              }
            }
          }

          table.mat-table {
            tr {
              &.mat-row {
                &:nth-child(odd) {
                  background-color: #e9eced;
                }
              }

              &.mat-header-row {
                font-family: Poppins, Moul, Khmer, system ui;
              }

              &.mat-header-row,
              &.mat-row,
              &.mat-footer-row {
                height: 50px;
              }

              &.mat-row {
                font-weight: bold;
              }

              th,
              td {
                border: 0;
                print-color-adjust: exact;
                padding-inline: 5px;

                &.mat-column-name {
                  width: 446px;
                }

                &.mat-column-name_en {
                  width: 465px;
                }
              }

              th {
                color: rgb(35 32 33);
                font-size: 16px;
                text-align: center;
              }

              td {
                color: rgb(35 32 33);

                &.mat-column-groupHeader {
                  font-family: Poppins, Moul, Khmer, system ui;
                  font-size: 16px;
                }

                &.mat-cell {
                  &:first-of-type {
                    margin-inline: 10px;
                  }
                }

                &.font-size-12 {
                  font-size: 12px;
                }
              }
            }
          }

          table.mat-table.core-competency {
            tr.mat-header-row {
              display: none;
            }
          }
        }
      }
    }

    table {
      tbody {
        tr {
          td {
            &.mat-column-code {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  // student competency
  .template {
    &.student-competency {
      width: 994px;
      height: auto;
    }
  }
}

.create-issue-certificate {
  .creation-form {
    .box {
      border: 1px solid #edf2f9;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.6em 0.75em 0.6em 0.75em;
      cursor: pointer;

      &.invalid {
        border-width: 2px;
        border-color: #fc7e7e;
      }

      .mat-icon {
        width: 10px;
      }

      .group-picture-name {
        display: flex;
        align-items: center;
        gap: 12px;

        .profile-picture-list {
          margin: 0;
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

.list-certificate-dialog {
  .mat-table {
    tbody {
      tr {
        &.mat-row {
          cursor: pointer;
        }
      }
    }
  }
}

.issued-candidate-list {
  .mat-column-table-gender {
    max-width: 80px;
  }
}

#certificate-competency {
  .text-overlay {
    position: absolute;
    inset: 0;
    padding: 29em 3.5em 25.5em 3.5em;

    .section-wrapper {
      margin-block: 15px;
      display: grid;
      gap: 16px;
      padding-inline: 40px;

      p {
        text-align: center;
      }

      &.last-section {
        margin-block: 14px;
      }
    }
  }

  .table-data {
    min-height: 281.5px;
    max-height: 281.5px;
    padding-inline: 20px;

    table.mat-table {
      border-spacing: 20px 0;
      background-color: #fff;

      tr {
        &.mat-header-row,
        &.mat-row,
        &.mat-footer-row {
          height: 35px;
        }

        th {
          color: rgb(35 32 33);
          font-family: 'Moul';
          background-color: transparent;
          border-top-width: 0;
          border-bottom-width: 6px;
          border-bottom-color: #fff;

          &:last-child {
            border-right-width: 0;
          }
        }

        td {
          color: rgb(35 32 33);
          border-bottom: 1px dotted #000;

          &:first-of-type {
            border-style: solid;
          }

          &.mat-column-code {
            white-space: nowrap;
          }
        }

        th,
        td {
          font-weight: bold;
          padding: 0;
          line-height: 17.2px;

          &.mat-column-name {
            width: 360px;
          }

          &.mat-column-name_en {
            width: 400px;
          }
        }
      }
    }
  }

  .table-data {
    // height: $table-data-height + px;

    // for loop class count > 8
    @for $i from 0 to 15 {
      // class name: count-[9,10,...]
      &.count-#{$i} {
        table.mat-table {
          tr.mat-header-row,
          tr.mat-row,
          tr.mat-footer-row {
            // scale row height by row count
            height: calc(($table-data-height) / $i) + px;
          }
        }
      }
    }
  }

  @include print-style();
}

#frontTemplate {
  @include print-style();

  .text-overlay {
    position: absolute;
    inset: 0;
    padding: 17.85em 5.4em 22.6em 5.4em;

    .section-wrapper {
      margin-block: 15px;
      gap: 14px;

      p {
        text-align: center;
        font-size: 14px;
      }

      .khmer {
        width: 100%;
        padding-left: 8px;
      }

      .english {
        width: 100%;
      }

      &.last-section {
        margin-block: 14px;
      }
    }
  }

  .profile-image {
    transform: translate(-0.34px, -122.5px);

    .profile-thumbnail {
      width: 91px;
      height: 105px;
    }
  }

  .certificate-code {
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    text-align: center;
  }
}
